$b-g-p-medium: "basis_grotesquemedium";
$b-g-p-black: "basis_grotesqueblack";
$b-g-p-bold: "basis_grotesquebold";
$b-g-p-light: "basis_grotesquelight";
$b-g-mono: "basis_grotesquemono";
$b-g-mono-bold: "basis_grotesquemono_bold";
$b-g-p-regular: "basis_grotesqueregular";
$aeonik-thin: "aeonikthin";
$aeonik-air: "aeonikair";
$aeonik-black: "aeonikblack";
$aeonik-bold: "aeonikbold";
$aeonik-light: "aeoniklight";
$aeonik-medium: "aeonikmedium";
$aeonik-regular: "aeonikregular";
$athletics-black: "athleticsblack";
$athletics-bold: "athleticsbold";
$athletics-extrabold: "athleticsextrabold";
$athletics-light: "athleticslight";
$athletics-medium: "athleticsmedium";
$athletics-regular: "athleticsregular";

/* = = Colors = = */
$deepblue: #00091a;
$lightgreen: #ddff99;
$blue: #4080ff;

body {
  font-family: $b-g-p-regular;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

body {
  width: 100%;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  padding: 0;
  margin: 0;
  background-color: $deepblue;
}

#outlook a {
  padding: 0;
}
.es-button {
  mso-style-priority: 100 !important;
  text-decoration: none !important;
}
a[x-apple-data-detectors] {
  color: inherit !important;
  text-decoration: none !important;
  font-size: inherit !important;
  font-family: inherit !important;
  font-weight: inherit !important;
  line-height: inherit !important;
}
.es-desk-hidden {
  display: none;
  float: left;
  overflow: hidden;
  width: 0;
  max-height: 0;
  line-height: 0;
  mso-hide: all;
}
[data-ogsb] .es-button {
  border-width: 0 !important;
  padding: 10px 30px 10px 30px !important;
}
@media only screen and (max-width: 600px) {
  p,
  ul li,
  ol li,
  a {
    line-height: 150% !important;
  }
  h1,
  h2,
  h3,
  h1 a,
  h2 a,
  h3 a {
    line-height: 120% !important;
  }
  h1 {
    font-size: 36px !important;
    text-align: left;
  }
  h2 {
    font-size: 26px !important;
    text-align: left;
  }
  h3 {
    font-size: 20px !important;
    text-align: left;
  }
  .es-header-body h1 a,
  .es-content-body h1 a,
  .es-footer-body h1 a {
    font-size: 36px !important;
    text-align: left;
  }
  .es-header-body h2 a,
  .es-content-body h2 a,
  .es-footer-body h2 a {
    font-size: 26px !important;
    text-align: left;
  }
  .es-header-body h3 a,
  .es-content-body h3 a,
  .es-footer-body h3 a {
    font-size: 20px !important;
    text-align: left;
  }
  .es-menu td a {
    font-size: 12px !important;
  }
  .es-header-body p,
  .es-header-body ul li,
  .es-header-body ol li,
  .es-header-body a {
    font-size: 14px !important;
  }
  .es-content-body p,
  .es-content-body ul li,
  .es-content-body ol li,
  .es-content-body a {
    font-size: 14px !important;
  }
  .es-footer-body p,
  .es-footer-body ul li,
  .es-footer-body ol li,
  .es-footer-body a {
    font-size: 14px !important;
  }
  .es-infoblock p,
  .es-infoblock ul li,
  .es-infoblock ol li,
  .es-infoblock a {
    font-size: 12px !important;
  }
  *[class="gmail-fix"] {
    display: none !important;
  }
  .es-m-txt-c,
  .es-m-txt-c h1,
  .es-m-txt-c h2,
  .es-m-txt-c h3 {
    text-align: center !important;
  }
  .es-m-txt-r,
  .es-m-txt-r h1,
  .es-m-txt-r h2,
  .es-m-txt-r h3 {
    text-align: right !important;
  }
  .es-m-txt-l,
  .es-m-txt-l h1,
  .es-m-txt-l h2,
  .es-m-txt-l h3 {
    text-align: left !important;
  }
  .es-m-txt-r img,
  .es-m-txt-c img,
  .es-m-txt-l img {
    display: inline !important;
  }
  .es-button-border {
    display: inline-block !important;
  }
  a.es-button,
  button.es-button {
    font-size: 20px !important;
    display: inline-block !important;
  }
  .es-adaptive table,
  .es-left,
  .es-right {
    width: 100% !important;
  }
  .es-content table,
  .es-header table,
  .es-footer table,
  .es-content,
  .es-footer,
  .es-header {
    width: 100% !important;
    max-width: 600px !important;
  }
  .es-adapt-td {
    display: block !important;
    width: 100% !important;
  }
  .adapt-img {
    width: 100% !important;
    height: auto !important;
  }
  .es-m-p0 {
    padding: 0 !important;
  }
  .es-m-p0r {
    padding-right: 0 !important;
  }
  .es-m-p0l {
    padding-left: 0 !important;
  }
  .es-m-p0t {
    padding-top: 0 !important;
  }
  .es-m-p0b {
    padding-bottom: 0 !important;
  }
  .es-m-p20b {
    padding-bottom: 20px !important;
  }
  .es-mobile-hidden,
  .es-hidden {
    display: none !important;
  }
  tr.es-desk-hidden,
  td.es-desk-hidden,
  table.es-desk-hidden {
    width: auto !important;
    overflow: visible !important;
    float: none !important;
    max-height: inherit !important;
    line-height: inherit !important;
  }
  tr.es-desk-hidden {
    display: table-row !important;
  }
  table.es-desk-hidden {
    display: table !important;
  }
  td.es-desk-menu-hidden {
    display: table-cell !important;
  }
  .es-menu td {
    width: 1% !important;
  }
  table.es-table-not-adapt,
  .esd-block-html table {
    width: auto !important;
  }
  table.es-social {
    display: inline-block !important;
  }
  table.es-social td {
    display: inline-block !important;
  }
  .es-m-p5 {
    padding: 5px !important;
  }
  .es-m-p5t {
    padding-top: 5px !important;
  }
  .es-m-p5b {
    padding-bottom: 5px !important;
  }
  .es-m-p5r {
    padding-right: 5px !important;
  }
  .es-m-p5l {
    padding-left: 5px !important;
  }
  .es-m-p10 {
    padding: 10px !important;
  }
  .es-m-p10t {
    padding-top: 10px !important;
  }
  .es-m-p10b {
    padding-bottom: 10px !important;
  }
  .es-m-p10r {
    padding-right: 10px !important;
  }
  .es-m-p10l {
    padding-left: 10px !important;
  }
  .es-m-p15 {
    padding: 15px !important;
  }
  .es-m-p15t {
    padding-top: 15px !important;
  }
  .es-m-p15b {
    padding-bottom: 15px !important;
  }
  .es-m-p15r {
    padding-right: 15px !important;
  }
  .es-m-p15l {
    padding-left: 15px !important;
  }
  .es-m-p20 {
    padding: 20px !important;
  }
  .es-m-p20t {
    padding-top: 20px !important;
  }
  .es-m-p20r {
    padding-right: 20px !important;
  }
  .es-m-p20l {
    padding-left: 20px !important;
  }
  .es-m-p25 {
    padding: 25px !important;
  }
  .es-m-p25t {
    padding-top: 25px !important;
  }
  .es-m-p25b {
    padding-bottom: 25px !important;
  }
  .es-m-p25r {
    padding-right: 25px !important;
  }
  .es-m-p25l {
    padding-left: 25px !important;
  }
  .es-m-p30 {
    padding: 30px !important;
  }
  .es-m-p30t {
    padding-top: 30px !important;
  }
  .es-m-p30b {
    padding-bottom: 30px !important;
  }
  .es-m-p30r {
    padding-right: 30px !important;
  }
  .es-m-p30l {
    padding-left: 30px !important;
  }
  .es-m-p35 {
    padding: 35px !important;
  }
  .es-m-p35t {
    padding-top: 35px !important;
  }
  .es-m-p35b {
    padding-bottom: 35px !important;
  }
  .es-m-p35r {
    padding-right: 35px !important;
  }
  .es-m-p35l {
    padding-left: 35px !important;
  }
  .es-m-p40 {
    padding: 40px !important;
  }
  .es-m-p40t {
    padding-top: 40px !important;
  }
  .es-m-p40b {
    padding-bottom: 40px !important;
  }
  .es-m-p40r {
    padding-right: 40px !important;
  }
  .es-m-p40l {
    padding-left: 40px !important;
  }
}

.textfield-container input {
  /* background-color: #fff; */
  /* width: 100%; */
}
.dive-log-details-inner-cont {
  background-color: #fff;
  border-radius: 10px;
  width: 100%;
  padding: 10px;
}
.dive-log-details-header-cont {
  font-size: 18px;
  text-align: center;
  font-weight: bold;
  color: #232323;
  padding: 10px 20px;
}
.dive-log-details-content-item {
  display: flex;
  flex-direction: row;
}
.dive-log-details-content-item-left {
  display: flex;
  width: 50px;
  height: 50px;
  justify-content: center;
  align-items: center;
  /* background-color: red; */
}
.dive-log-details-content-item-left img {
  width: 30px;
  height: 30px;
}
.dive-log-details-content-item-center {
  display: flex;
  flex: 1;
  /* background-color: yellow; */
  /* justify-content: center; */
  align-items: center;
  padding-left: 10px;
}
.dive-log-details-content-item-right {
  display: flex;
  /* width: 100px; */
  /* background-color: green; */
  justify-content: center;
  align-items: center;
  background-color: #4080ff;
  color: #fff;
  border-radius: 20px;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 5px;
  padding-bottom: 5px;
  font-size: 14px;
  font-weight: bold;
}
.dive-log-details-content-item-center {
  color: #00091a;
}
.dive-log-details-content-item {
  padding: 10px 0px;
  border-bottom: 1px solid #eaedf0;
}
.dive-log-details-content-item-right-title {
  font-size: 14px;
  font-weight: bold;
}
.dive-log-details-content-item-right-subtitle {
  font-size: 12px;
  font-weight: normal;
}
.dive-log-details-content-item-right.location {
  flex-direction: column;
  align-items: flex-end;
}
.dive-log-details-content-item-center.button {
  text-align: center;
  align-items: center;
  justify-content: center;
}
.dive-log-details-content-item.buttons {
  border-width: 0px;
  padding-top: 50px;
}
.dive-header-logo-img-black {
  height: 32px;
}
.dive-header-logo-img-white {
  height: 0px;
}
.main-site-wrapper-wide {
  // max-width: 1360px;
  margin: 0 auto;
}
.main-site-wrapper {
  max-width: 767px;
  margin: 0 auto;
}
.dive-main-cont {
  background-color: #070527;
}
.dive-header-cont {
  height: 64px;
  border-color: #fff;
  background-color: #ffffff;
}

.dive-header-btn-cont {
  display: none;
}
.map-details-cont {
  display: grid;
}
.notify {
  // border-top: 1px solid #e7eaed;
  // border-bottom: 1px solid #e7eaed;
}
@media (min-width: 768px) {
  .map-details-cont {
    grid-template-columns: repeat(2, 1fr);
  }
  .map-details-sub {
  }
  .dive-green-cont {
    // height: 168px;
  }
  .dive-blue-cont {
    // height: 240px;
  }
  .map-details-sub-1 {
    // padding-top: 0px;
    // padding-left: 10px;
  }
  .map-details-sub-2 {
    padding-top: 0px;
    // padding-left: 10px;
  }
  .map-view {
    // height: 418px !important;
    padding-bottom: 0px !important;
  }
  .dive-details-top-right-box,
  .media-details-tag-item {
    background: #f2f4f7 !important;
  }
  .media-dive-date,
  .media-shop,
  .media-details-top-title,
  .media-details-tag-item {
    color: #00091a !important;
  }
  .media-details-top-subtitle {
    color: #8a9099 !important;
  }
  .air-dark {
    display: flex;
  }
  .air-lite {
    display: none;
  }
  .hide {
    display: none;
  }
}
@media (max-width: 767px) {
  .dive-header-cont {
    background-color: #070527;
  }
  .dive-header-btn-cont {
    display: flex;
  }
  .header-user-details {
    display: none;
  }
  .dive-header-logo-img-black {
    height: 0px;
  }
  .dive-header-logo-img-white {
    height: 32px;
  }
  .footer-cont {
    display: none;
  }
  #user-main-cont-id.dive-header-cont {
    display: none;
  }
  .dive-main-cont.bg-white,
  body.bg-white {
    // background-color: #070527;
  }
  .notify {
    display: none;
  }
  .air-dark {
    display: none;
  }
}
.user-details {
  background-color: #070527;
}
.btn {
  height: 40px;
  background-color: #ff7062;
  color: #fff;
  padding: 0 20px;
  border-radius: 6px;
}
.btn-lite {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 48px;
  width: 157px;
  border-radius: 24px;
  padding: 19px, 20px;
  background: #ffffff;
  border: 2px solid #e7eaed;
  box-sizing: border-box;
  border-radius: 24px;
}
.btn-orange {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 19px 20px;
  width: 158px;
  height: 48px;
  background: #ff7466;
  border-radius: 24px;
}
.btn-orange-signup {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 19px 20px;
  // width: 158px;
  height: 30px;
  background: #ff7466;
  border-radius: 5px;
  color: #fff;
}
.dive-details-title {
  font-family: $athletics-bold;
  font-size: 32px;
  line-height: 32px;
  color: #fff;
}
.dive-details-top-right-box {
  background: rgba(255, 255, 255, 0.15);
}
.dive-details-top-title {
  font-family: $b-g-p-medium;
  color: #fff;
  font-size: 17px;
}
.dive-details-top-subtitle {
  font-family: $b-g-p-medium;
  color: #5c6066;
  font-size: 13px;
  line-height: 15px;
}
.dive-details-date {
  font-family: $athletics-bold;
  font-size: 24px;
  line-height: 30px;
  /* identical to box height, or 125% */

  /* White */

  color: #ffffff;
}
.text-grey {
  color: #5c6066;
}
.dive-details-shop {
  font-size: 15px;
  line-height: 20px;
  /* identical to box height, or 133% */

  /* Gray 1 */

  color: #fff;
}
.dive-details-tag-item {
  color: #fff;
  background: rgba(255, 255, 255, 0.15);
  font-family: $b-g-p-regular;
}
.dive-details-location-abs-cont {
  background-color: rgba(255, 255, 255, 0.15);
}
.dive-details-location-title {
  font-size: 17px;
  line-height: 20px;
  /* identical to box height, or 115% */

  /* White */

  color: #ffffff;
  font-family: $b-g-p-regular;
}
.dive-details-location-subtitle {
  font-size: 13px;
  line-height: 15px;
  /* identical to box height, or 115% */

  /* White */

  color: #ffffff;
}
.dive-green-cont {
  background-color: $lightgreen;
}
.dive-blue-cont {
  background-color: $blue;
}
.dive-green-title {
  font-size: 17px;
  line-height: 20px;
  /* identical to box height, or 118% */

  /* Green Dark 2 */

  color: #4d661a;
}
.dive-blue-cont .dive-green-title {
  color: #fff;
}
.dive-green-item-title {
  // font-family: $b-g-p-regular;
  font-size: 13px;
  line-height: 15px;

  /* identical to box height, or 115% */

  /* Green Dark 2 */

  color: #4d661a;
}
.dive-green-item-subtitle {
  font-size: 36px;
  line-height: 36px;
  padding-top: 2px;
  /* identical to box height, or 83% */

  /* Green Dark 2 */
  font-family: $athletics-bold;
  color: #4d661a;
}

.dive-blue-cont .dive-green-item-title,
.dive-blue-cont .dive-green-item-subtitle {
  color: #fff;
}

.dive-white-cont {
  background-color: #fff;
}
.dive-white-cont .dive-green-title {
  color: $deepblue;
}
.dive-white-desc {
  font-size: 17px;
  line-height: 24px;
  /* or 141% */

  /* Gray 2 */

  color: #8a9099;
}
.free-dive-cont {
  color: #fff;
}
.free-dive-header {
  font-size: 13px;
  line-height: 15px;
  border-color: rgba(255, 255, 255, 0.1);
}
.frc-tag {
  font-size: 13px;
  line-height: 10px;
  font-family: $b-g-p-medium;
  color: #fff;
  height: 20px;
  background: rgba(255, 255, 255, 0.15);
  border-radius: 20px;
  padding-left: 8px;
  padding-right: 8px;
  padding-top: 6px;
}
.frc-tag > span {
  display: block;
}
.free-dive-highlight {
  font-size: 24px;
  line-height: 20px;
  /* identical to box height, or 83% */

  /* White */

  color: #ffffff;
  font-family: $athletics-bold;
}
.free-dive-small {
  font-size: 13px;
  line-height: 15px;
  color: #ffffff;
}
.surface-interval-cont {
  background-color: rgba(255, 255, 255, 0.1);
  font-size: 13px;
  line-height: 15px;
  /* identical to box height, or 115% */

  /* White */

  color: #ffffff;
}
.p-p-text {
  color: #fff !important;
}
.prose :where(h2):not(:where([class~="not-prose"] *)) {
  color: #fff !important;
}
.header-text-name-subtitle {
  font-family: $b-g-p-regular;
  font-size: 11px;
  font-weight: 400;
  line-height: 14px;
  letter-spacing: 0em;
  text-align: right;
}
.header-text-name-title {
  font-family: $b-g-p-regular;
  font-size: 15px;
  font-weight: 600;
  line-height: 19px;
  letter-spacing: 0em;
}
.footer-cont {
  background-color: #070527;
}
.footer-cont-text {
  font-family: $athletics-regular;
  font-size: 20px;
  font-weight: 400;
  line-height: 30px;
  letter-spacing: 0em;
  color: #f3f5f8;
  text-align: right;
}
.dive-footer-logo-img {
  height: 32px;
  top: -15px;
  right: 145px;
}
.bg-white {
  background-color: #ffffff;
}
.notify-text {
  height: 24px;
  font-family: $athletics-regular;
  font-weight: 400;
  font-size: 17px;
  line-height: 24px;
  display: flex;
  align-items: center;
  color: #00091a;
  margin: 0px 8px;
}
.btn-lite-txt {
  font-family: $athletics-regular;
  font-weight: 700;
  font-size: 17px;
  line-height: 16px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #00091a;
  margin: 0px 15px;
}
.btn-orange-txt {
  font-family: $athletics-regular;
  font-weight: 700;
  font-size: 17px;
  line-height: 16px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #ffffff;
  margin: 0px 15px;
}

.user-detail-txt {
  color: #fff;
}
// .dive-main-cont.bg-white,
// body.bg-white {
//   background-color: grey;
// }
.html-content h1 {
  font-family: $athletics-bold;
  font-weight: 700;
  font-size: 36px;
}
.html-content h2 {
  font-family: $athletics-bold;
  font-weight: 700;
  font-size: 24px;
}
.html-content img {
  border-radius: 8px;
}
.html-content p {
  font-size: 15px;
  color: #8a9099;
}
.html-content a {
  color: #4080ff;
}
.slick-dots li button:before {
  height: 10px !important;
  width: 10px !important;
  border-radius: 5px !important;
  color: transparent !important;
  background-color: rgb(149, 149, 149);
}
.slick-dots li.slick-active button:before {
  height: 10px;
  width: 10px;
  border-radius: 5px;
  color: transparent !important;
  background-color: rgb(255, 116, 102);
}
