/*! Generated by Font Squirrel (https://www.fontsquirrel.com) on January 20, 2022 */

@font-face {
  font-family: 'basis_grotesqueblack';
  src: url('basisgrotesque-black-webfont.woff2') format('woff2'),
    url('basisgrotesque-black-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'basis_grotesquebold';
  src: url('basisgrotesque-bold-webfont.woff2') format('woff2'),
    url('basisgrotesque-bold-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'basis_grotesquelight';
  src: url('basisgrotesque-light-webfont.woff2') format('woff2'),
    url('basisgrotesque-light-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'basis_grotesquemedium';
  src: url('basisgrotesque-medium-webfont.woff2') format('woff2'),
    url('basisgrotesque-medium-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'basis_grotesquemono';
  src: url('basisgrotesque-mono-webfont.woff2') format('woff2'),
    url('basisgrotesque-mono-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'basis_grotesquemono_bold';
  src: url('basisgrotesque-monobold-webfont.woff2') format('woff2'),
    url('basisgrotesque-monobold-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'basis_grotesqueregular';
  src: url('basisgrotesque-regular-webfont.woff2') format('woff2'),
    url('basisgrotesque-regular-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'aeonikthin';
  src: url('aeonik-thin-webfont.woff2') format('woff2'),
    url('aeonik-thin-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'aeonikair';
  src: url('aeonik-air-webfont.woff2') format('woff2'),
    url('aeonik-air-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'aeonikblack';
  src: url('aeonik-black-webfont.woff2') format('woff2'),
    url('aeonik-black-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'aeonikbold';
  src: url('aeonik-bold-webfont.woff2') format('woff2'),
    url('aeonik-bold-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'aeoniklight';
  src: url('aeonik-light-webfont.woff2') format('woff2'),
    url('aeonik-light-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'aeonikmedium';
  src: url('aeonik-medium-webfont.woff2') format('woff2'),
    url('aeonik-medium-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'aeonikregular';
  src: url('aeonik-regular-webfont.woff2') format('woff2'),
    url('aeonik-regular-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'athleticsblack';
  src: url('athletics-black-webfont.woff2') format('woff2'),
    url('athletics-black-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'athleticsbold';
  src: url('athletics-bold-webfont.woff2') format('woff2'),
    url('athletics-bold-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'athleticsextrabold';
  src: url('athletics-extrabold-webfont.woff2') format('woff2'),
    url('athletics-extrabold-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'athleticslight';
  src: url('athletics-light-webfont.woff2') format('woff2'),
    url('athletics-light-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'athleticsmedium';
  src: url('athletics-medium-webfont.woff2') format('woff2'),
    url('athletics-medium-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'athleticsregular';
  src: url('athletics-regular-webfont.woff2') format('woff2'),
    url('athletics-regular-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}
